var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('img', {
    staticStyle: {
      "width": "130px"
    },
    attrs: {
      "src": require("../../../../public/assets/logo-balanja.png"),
      "alt": ""
    }
  })]), _c('b-card-title', {
    staticClass: "mb-1 text-center"
  }, [_vm._v(" Selamat Datang di " + _vm._s(_vm.appName) + " ")]), _c('b-card-text', {
    staticClass: "mb-2 text-center"
  }, [_vm._v(" Silahkan masuk dengan akun anda dan atur bisnismu! ")]), _c('validation-observer', {
    ref: "loginForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-login-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "email",
            "label": "Email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "name": "login-email",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "john@example.com",
                  "autofocus": ""
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "password"
          }
        }, [_vm._v("Password")])]), _c('validation-provider', {
          attrs: {
            "name": "Password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "password",
                  "type": _vm.passwordFieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "login-password",
                  "placeholder": "Password"
                },
                model: {
                  value: _vm.password,
                  callback: function callback($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIcon
                },
                on: {
                  "click": _vm.togglePasswordVisibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "password"
          }
        }), _c('b-link', {
          attrs: {
            "to": {
              path: 'forgot-password'
            }
          }
        }, [_c('small', [_vm._v("Lupa Password?")])])], 1)], 1), _c('b-button', {
          staticClass: "gradient-balanja border border-0",
          attrs: {
            "variant": "info",
            "type": "submit",
            "block": "",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_c('strong', [_vm._v(_vm._s(_vm.loginLabel))])])], 1)];
      }
    }])
  }), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("Belum punya akun? ")]), _c('a', {
    attrs: {
      "href": "https://balanja.id/daftar"
    }
  }, [_c('span', [_vm._v("Daftar Sekarang")])])])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }